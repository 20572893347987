/**
 * Module dependencies
 */
import React from 'react';
import PropTypes from 'prop-types';

import TrendsCarousel from '@seo-frontend-components/trends-carousel';

import { useStaticProps } from '../context/static-props';

/**
 * CategoryTrendCarousel Component
 */
const CategoryTrendCarousel = (props) => {
  const { trends_categories } = props;

  const { deviceType, jsDisabled, webp: supportWebP } = useStaticProps();

  const viewData = {
    trendsToViewMinDefault: 3,
    trendsToViewDefault: 4,
    minWidthToShowAllEntries: 1275,
  };

  return (
    <TrendsCarousel
      trends_categories={trends_categories}
      deviceType={deviceType}
      jsDisabled={jsDisabled}
      supportWebP={supportWebP}
      trendsToViewMinDefault={viewData.trendsToViewMinDefault}
      trendsToViewDefault={viewData.trendsToViewDefault}
      minWidthToShowAllEntries={viewData.minWidthToShowAllEntries}
      thumbnailSize={48}
    />
  );
};

CategoryTrendCarousel.propTypes = {
  trends_categories: PropTypes.shape({
    title: PropTypes.string.isRequired,
    trends: PropTypes.arrayOf(
      PropTypes.shape({
        description: PropTypes.string.isRequired,
        images: PropTypes.arrayOf(
          PropTypes.shape({
            description: PropTypes.string.isRequired,
            url: PropTypes.string.isRequired,
          }),
        ).isRequired,
        keyword: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
      }),
    ),
  }),
};

/**
 * Inject i18n context as props into CategoryTrendCarousel.
 */
export default CategoryTrendCarousel;
